/**
 * Convert Data URI to Blob.
 *
 * @param {string} dataURI
 * @param {string} mimeType image/jpeg, image/png, application/x-pdf
 * @returns {Blob}
 */
window.__dataURIToBlob = (dataURI, mimeType) => {
    const trimmedString = dataURI.replace(`data:${mimeType};base64,`, '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }

    return new Blob([buffer], {
        type: mimeType,
    });
}

/**
 * Convert Data URI to File.
 *
 * @param {string} dataURI
 * @param {string} mimeType image/jpeg, image/png, application/x-pdf
 * @returns {File}
 */
window.__dataURIToFile = (dataURI, mimeType) => {
    const blob = window.__dataURIToBlob(dataURI, mimeType);

    return new File([blob], {
        lastModified: new Date().getTime(),
        type: mimeType,
    });
}
