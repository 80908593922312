/**
 * Resize image.
 * @param {File} file
 * @param {string} mimeType
 * @param {number?} maxWidth
 * @param {number?} scale
 * @param {number?} quality
 * @returns {Promise<string>}
 */
window.__resizeImage = (file, mimeType, maxWidth = 2048, scale = 0.5, quality = 0.7) => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            img.onload = () => {
                const origWidth = img.naturalWidth;
                const origHeight = img.naturalHeight;

                let desiredWidth = maxWidth;

                const ratio = desiredWidth / origWidth;
                let correspondingHeight = ratio * origHeight;

                desiredWidth = desiredWidth * scale;
                correspondingHeight = correspondingHeight * scale;

                canvas.width = desiredWidth;
                canvas.height = correspondingHeight;

                ctx.drawImage(img, 0, 0, desiredWidth, correspondingHeight);

                resolve(canvas.toDataURL(mimeType, quality));
            }

            img.src = e.target.result;

        };

        reader.readAsDataURL(file)
    })
};
